import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createResourceLoader, ResourceType } from '@tager/web-core';

import { City } from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';
import { getCitiesList } from '@/services/requests';

const citiesLoader = createResourceLoader<Array<City>>([]);

type State = {
  citiesList: ResourceType<Array<City>>;
};

const initialState: State = {
  citiesList: citiesLoader.getInitialResource(),
};

const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    citiesListRequestPending(state) {
      state.citiesList = citiesLoader.pending();
    },
    citiesListRequestFullfiled(state, action: PayloadAction<Array<City>>) {
      state.citiesList = citiesLoader.fulfill(action.payload);
    },
    citiesListRequestRejected(state) {
      state.citiesList = citiesLoader.reject();
    },
  },
});

const { actions, reducer } = citiesSlice;
export const {
  citiesListRequestFullfiled,
  citiesListRequestPending,
  citiesListRequestRejected,
} = actions;

export default reducer;

export function getCitiesListThunk(option?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<City>>> {
  return async (dispatch, getState): Promise<Array<City>> => {
    try {
      dispatch(citiesListRequestPending());
      const response = await getCitiesList();
      dispatch(citiesListRequestFullfiled(response.data));
      return response.data;
    } catch (e) {
      return [];
    }
  };
}

export function selectCitiesListResource(
  state: AppState
): ResourceType<Array<City>> {
  return state.tager.cities.citiesList;
}

export function selectCitiesList(state: AppState): Array<City> {
  return selectCitiesListResource(state).data;
}
