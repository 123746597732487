import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapEntry } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { InitialValueProps } from '@/modules/Promotions/components/PromotionContent';

type FilterState = {
  filterMap: Record<string, InitialValueProps>;
};

const initialState: FilterState = {
  filterMap: {},
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    addedFilter(
      state,
      action: PayloadAction<MapEntry<string, InitialValueProps>>
    ) {
      state.filterMap[action.payload.key] = action.payload.value;
    },
  },
});

const { actions, reducer } = filterSlice;

export const { addedFilter } = actions;

export default reducer;

export function saveAdditionFiltersByAliasThunk(
  categoryAlias: string,
  filters: InitialValueProps
): AppThunk<boolean> {
  return (dispatch) => {
    try {
      dispatch(addedFilter({ key: categoryAlias, value: filters }));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
}

export function getAdditionFiltersByAlias(
  state: AppState,
  categoryAlias: string
): InitialValueProps | undefined {
  return state.filter.filterMap[categoryAlias];
}
