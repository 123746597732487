import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapEntry, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { ShoppingCenterItem } from '@/typings/model';
import { getFashionsMollByAlias } from '@/services/requests';

type State = {
  fashionsMollFullMap: Record<string, ShoppingCenterItem>;
};

const initialState: State = {
  fashionsMollFullMap: {},
};

const fashionsMollSlice = createSlice({
  name: 'fashionsMoll',
  initialState,
  reducers: {
    fashionsMollAdded(
      state,
      action: PayloadAction<MapEntry<string, ShoppingCenterItem>>
    ) {
      state.fashionsMollFullMap[action.payload.key] = action.payload.value;
    },
  },
});

const { actions, reducer } = fashionsMollSlice;
export const { fashionsMollAdded } = actions;

export default reducer;

export function getFashionsMollAddedByAliasThunk(
  fashionsMollPath: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<ShoppingCenterItem>>> {
  return async (dispatch, getState) => {
    try {
      const post = selectFashionsMollByAlias(getState(), fashionsMollPath);

      if (!options?.shouldInvalidate && post) {
        return post;
      }

      const response = await getFashionsMollByAlias(fashionsMollPath);

      dispatch(
        fashionsMollAdded({
          key: fashionsMollPath,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      return null;
    }
  };
}

export function selectFashionsMollByAlias(
  state: AppState,
  fashionsMollAlias: string
): ShoppingCenterItem | undefined {
  return state.tager.fashionsMoll.fashionsMollFullMap[fashionsMollAlias];
}
