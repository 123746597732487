import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { PromotionListTypeItem } from '@/typings/model';
import { getPromotionsTypes } from '@/services/requests';

const promotionsTypesLoader = createResourceLoader<
  Array<PromotionListTypeItem>
>([]);

type State = {
  promotionsTypesList: ResourceType<Array<PromotionListTypeItem>>;
};

const initialState: State = {
  promotionsTypesList: promotionsTypesLoader.getInitialResource(),
};

const promotionsTypesSlice = createSlice({
  name: 'promotionsTypes',
  initialState,
  reducers: {
    promotionsTypesListRequestPending(state) {
      state.promotionsTypesList = promotionsTypesLoader.pending();
    },
    promotionsTypesListRequestFulfilled(
      state,
      action: PayloadAction<Array<PromotionListTypeItem>>
    ) {
      state.promotionsTypesList = promotionsTypesLoader.fulfill(action.payload);
    },
    promotionsTypesListRequestRejected(state) {
      state.promotionsTypesList = promotionsTypesLoader.reject();
    },
  },
});

const { actions, reducer } = promotionsTypesSlice;
export const {
  promotionsTypesListRequestPending,
  promotionsTypesListRequestFulfilled,
  promotionsTypesListRequestRejected,
} = actions;

export default reducer;

export function getPromotionsTypesListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<PromotionListTypeItem>>> {
  return async (dispatch, getState) => {
    const promotionsTypesListResource = selectPromotionsTypesListResource(
      getState()
    );

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      promotionsTypesListResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return promotionsTypesListResource.data;
    }

    dispatch(promotionsTypesListRequestPending());

    try {
      const response = await getPromotionsTypes();
      dispatch(promotionsTypesListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(promotionsTypesListRequestRejected());
      return [];
    }
  };
}

export function selectPromotionsTypesListResource(
  state: AppState
): ResourceType<Array<PromotionListTypeItem>> {
  return state.tager.promotionsTypes.promotionsTypesList;
}

export function selectPromotionsTypesList(
  state: AppState
): Array<PromotionListTypeItem> {
  return selectPromotionsTypesListResource(state).data;
}
