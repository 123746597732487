import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { NewsListItem } from '@/typings/model';
import { getNewsList } from '@/services/requests';

const newsListLoader = createResourceLoader<Array<NewsListItem>>([]);

type State = {
  newsList: ResourceType<Array<NewsListItem>>;
};

const initialState: State = {
  newsList: newsListLoader.getInitialResource(),
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    newsListRequestPending(state) {
      state.newsList = newsListLoader.pending();
    },
    newsListRequestFulfilled(
      state,
      action: PayloadAction<Array<NewsListItem>>
    ) {
      state.newsList = newsListLoader.fulfill(action.payload);
    },
    newsListRequestRejected(state) {
      state.newsList = newsListLoader.reject();
    },
  },
});

const { actions, reducer } = newsSlice;
export const {
  newsListRequestPending,
  newsListRequestFulfilled,
  newsListRequestRejected,
} = actions;

export default reducer;

export function getNewsListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<NewsListItem>>> {
  return async (dispatch, getState) => {
    const newsListResource = selectNewsListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      newsListResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return newsListResource.data;
    }

    dispatch(newsListRequestPending());

    try {
      const response = await getNewsList();
      dispatch(newsListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(newsListRequestRejected());
      return [];
    }
  };
}

export function selectNewsListResource(
  state: AppState
): ResourceType<Array<NewsListItem>> {
  return state.tager.news.newsList;
}

export function selectNewsList(state: AppState): Array<NewsListItem> {
  return selectNewsListResource(state).data;
}
