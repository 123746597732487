import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { PromotionListTypeItem } from '@/typings/model';
import { getPromotionsCategories } from '@/services/requests';

const promotionsCategoriesLoader = createResourceLoader<
  Array<PromotionListTypeItem>
>([]);

type State = {
  promotionsCategoriesList: ResourceType<Array<PromotionListTypeItem>>;
};

const initialState: State = {
  promotionsCategoriesList: promotionsCategoriesLoader.getInitialResource(),
};

const promotionsCategoriesSlice = createSlice({
  name: 'promotionsCategories',
  initialState,
  reducers: {
    promotionsCategoriesListRequestPending(state) {
      state.promotionsCategoriesList = promotionsCategoriesLoader.pending();
    },
    promotionsCategoriesListRequestFulfilled(
      state,
      action: PayloadAction<Array<PromotionListTypeItem>>
    ) {
      state.promotionsCategoriesList = promotionsCategoriesLoader.fulfill(
        action.payload
      );
    },
    promotionsCategoriesListRequestRejected(state) {
      state.promotionsCategoriesList = promotionsCategoriesLoader.reject();
    },
  },
});

const { actions, reducer } = promotionsCategoriesSlice;
export const {
  promotionsCategoriesListRequestPending,
  promotionsCategoriesListRequestFulfilled,
  promotionsCategoriesListRequestRejected,
} = actions;

export default reducer;

export function getPromotionsCategoriesListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<PromotionListTypeItem>>> {
  return async (dispatch, getState) => {
    const promotionsCategoriesListResource =
      selectPromotionsCategoriesListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      promotionsCategoriesListResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return promotionsCategoriesListResource.data;
    }

    dispatch(promotionsCategoriesListRequestPending());

    try {
      const response = await getPromotionsCategories();
      dispatch(promotionsCategoriesListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(promotionsCategoriesListRequestRejected());
      return [];
    }
  };
}

export function selectPromotionsCategoriesListResource(
  state: AppState
): ResourceType<Array<PromotionListTypeItem>> {
  return state.tager.promotionsCategories.promotionsCategoriesList;
}

export function selectPromotionsCategoriesList(
  state: AppState
): Array<PromotionListTypeItem> {
  return selectPromotionsCategoriesListResource(state).data;
}
