import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { NewsSectionItem } from '@/typings/model';
import { getNewsSectionList } from '@/services/requests';

const newsSectionListLoader = createResourceLoader<Array<NewsSectionItem>>([]);

type State = {
  newsSectionList: ResourceType<Array<NewsSectionItem>>;
};

const initialState: State = {
  newsSectionList: newsSectionListLoader.getInitialResource(),
};

const newsSectionSlice = createSlice({
  name: 'newsSection',
  initialState,
  reducers: {
    newsSectionListRequestPending(state) {
      state.newsSectionList = newsSectionListLoader.pending();
    },
    newsSectionListRequestFulfilled(
      state,
      action: PayloadAction<Array<NewsSectionItem>>
    ) {
      state.newsSectionList = newsSectionListLoader.fulfill(action.payload);
    },
    newsSectionListRequestRejected(state) {
      state.newsSectionList = newsSectionListLoader.reject();
    },
  },
});

const { actions, reducer } = newsSectionSlice;
export const {
  newsSectionListRequestPending,
  newsSectionListRequestFulfilled,
  newsSectionListRequestRejected,
} = actions;

export default reducer;

export function getNewsSectionListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<NewsSectionItem>>> {
  return async (dispatch, getState) => {
    const newsSectionListResource = selectNewsSectionListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      newsSectionListResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return newsSectionListResource.data;
    }

    dispatch(newsSectionListRequestPending());

    try {
      const response = await getNewsSectionList();
      dispatch(newsSectionListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(newsSectionListRequestRejected());
      return [];
    }
  };
}

export function selectNewsSectionListResource(
  state: AppState
): ResourceType<Array<NewsSectionItem>> {
  return state.tager.newsSection.newsSectionList;
}

export function selectNewsSectionList(state: AppState): Array<NewsSectionItem> {
  return selectNewsSectionListResource(state).data;
}
