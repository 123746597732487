import { combineReducers } from 'redux';

import settingsReducer from './tager/settings';
import seoReducer from './tager/seo';
import bannersReducer from './tager/banners';
import pagesReducer from './tager/pages';
import menusReducer from './tager/menus';
import vacanciesReducer from './tager/vacancies';
import vacanciesByCitiesReducer from './vacanciesCities';
import vacanciesByCategoriesReducer from './vacanciesCategories';
import vacancyReducer from './tager/vacancy';
import postReducer from './tager/post';
import newsListReducer from './newsList';
import newsSectionReducer from './newsSection';
import searchReducer from './tager/search';
import shopReducer from './tager/shop';
import shopsTypeReducer from './shopsType';
import shopsTypeByCitiesReducer from './shopsTypeByCities';
import shopsByCitiesReducer from './shopsCities';
import promotionsCategoriesReducer from './promotionCategories';
import promotionsSubcategoriesReducer from './promotionSubcategories';
import promotionsTypesReducer from './promotionTypes';
import shoppingCenterByCitiesReducer from './shoppingCenterByCities';
import shoppingCenterReducer from './tager/shoppingCenter';
import shoppingCenterListReducer from './shoppingCenterList';
import productReducer from './products';
import filterReducer from './filter';
import productionFilterReducer from './productionFilter';
import salonCategoriesReducer from './salonsCategories';
import salonsReducer from './salonList';
import fashionMollsListReducer from './fashionMollsList';
import fashionsMollReducer from './tager/fashionsMoll';
import restaurantsListReducer from './restaurantsList';
import citiesListReducer from './tager/cities';
import newsPagesReducer from './newsPageTemplate';

const tagerReducer = combineReducers({
  banners: bannersReducer,
  menus: menusReducer,
  pages: pagesReducer,
  seo: seoReducer,
  settings: settingsReducer,
  vacancies: vacanciesReducer,
  vacanciesByCities: vacanciesByCitiesReducer,
  vacanciesByCategories: vacanciesByCategoriesReducer,
  vacancy: vacancyReducer,
  post: postReducer,
  shop: shopReducer,
  news: newsListReducer,
  newsSection: newsSectionReducer,
  shoppingCenter: shoppingCenterReducer,
  fashionsMoll: fashionsMollReducer,
  search: searchReducer,
  shopsType: shopsTypeReducer,
  shopsTypeByCities: shopsTypeByCitiesReducer,
  shopsByCities: shopsByCitiesReducer,
  promotionsCategories: promotionsCategoriesReducer,
  promotionsSubcategories: promotionsSubcategoriesReducer,
  promotionsTypes: promotionsTypesReducer,
  shoppingCenterList: shoppingCenterListReducer,
  shoppingCenterByCities: shoppingCenterByCitiesReducer,
  fashionMollsList: fashionMollsListReducer,
  restaurantsList: restaurantsListReducer,
  salons: salonsReducer,
  salonCategories: salonCategoriesReducer,
  cities: citiesListReducer,
  newsPages: newsPagesReducer,
});

const rootReducer = combineReducers({
  tager: tagerReducer,
  product: productReducer,
  filter: filterReducer,
  productionFilter: productionFilterReducer,
});

export default rootReducer;
