import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  Nullish,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';
import { getPageByPath } from '@tager/web-modules';

import { AppState, AppThunk } from '@/store/store';
import { getPagesByTemplate } from '@/services/requests';
import { NewsPageType } from '@/typings/model';

const newsPageTemplateLoader = createResourceLoader<NewsPageType | null>(null);

type State = {
  newsPageTemplate: ResourceType<NewsPageType | null>;
};

const initialState: State = {
  newsPageTemplate: newsPageTemplateLoader.getInitialResource(),
};

const newsPageTemplateSlice = createSlice({
  name: 'newsPages',
  initialState,
  reducers: {
    newsPageTemplateRequestPending(state) {
      state.newsPageTemplate = newsPageTemplateLoader.pending();
    },
    newsPageTemplateRequestFulfilled(
      state,
      action: PayloadAction<NewsPageType>
    ) {
      state.newsPageTemplate = newsPageTemplateLoader.fulfill(action.payload);
    },
    newsPageTemplateRequestRejected(state) {
      state.newsPageTemplate = newsPageTemplateLoader.reject();
    },
  },
});

const { actions, reducer } = newsPageTemplateSlice;
export const {
  newsPageTemplateRequestFulfilled,
  newsPageTemplateRequestPending,
  newsPageTemplateRequestRejected,
} = actions;

export default reducer;

export function getNewsPageTemplateThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Nullish<NewsPageType>>> {
  return async (dispatch, getState) => {
    const newsPageTemplateResource = selectNewsPageTemplateResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      newsPageTemplateResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return newsPageTemplateResource.data;
    }

    dispatch(newsPageTemplateRequestPending());

    try {
      let newsPages = await getPagesByTemplate('news');

      if (newsPages) {
        const newsPageTemplate = await getPageByPath(newsPages.data[0].path);
        dispatch(
          newsPageTemplateRequestFulfilled(
            newsPageTemplate.data as NewsPageType
          )
        );
        return newsPageTemplate.data as NewsPageType;
      } else {
        dispatch(newsPageTemplateRequestRejected());
        return null;
      }
    } catch (error) {
      dispatch(newsPageTemplateRequestRejected());
      return null;
    }
  };
}

export function selectNewsPageTemplateResource(
  state: AppState
): ResourceType<NewsPageType | null> {
  return state.tager.newsPages.newsPageTemplate;
}

export function selectNewsPageTemplate(state: AppState): NewsPageType | null {
  return selectNewsPageTemplateResource(state).data;
}
