import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { ShopsItemByCities } from '@/typings/model';
import { getShopListByCities } from '@/services/requests';

const shopsByCitiesLoader = createResourceLoader<Array<ShopsItemByCities>>([]);

type State = {
  shopsByCitiesList: ResourceType<Array<ShopsItemByCities>>;
};

const initialState: State = {
  shopsByCitiesList: shopsByCitiesLoader.getInitialResource(),
};

const shopsByCitiesSlice = createSlice({
  name: 'shopsByCities',
  initialState,
  reducers: {
    shopByCitiesListRequestPending(state) {
      state.shopsByCitiesList = shopsByCitiesLoader.pending();
    },
    shopByCitiesListRequestFulfilled(
      state,
      action: PayloadAction<Array<ShopsItemByCities>>
    ) {
      state.shopsByCitiesList = shopsByCitiesLoader.fulfill(action.payload);
    },
    shopByCitiesListRequestRejected(state) {
      state.shopsByCitiesList = shopsByCitiesLoader.reject();
    },
  },
});

const { actions, reducer } = shopsByCitiesSlice;
export const {
  shopByCitiesListRequestPending,
  shopByCitiesListRequestFulfilled,
  shopByCitiesListRequestRejected,
} = actions;

export default reducer;

export function getShopByCitiesListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<ShopsItemByCities>>> {
  return async (dispatch, getState) => {
    const shopByCitiesListResource = selectShopByCitiesListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      shopByCitiesListResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return shopByCitiesListResource.data;
    }

    dispatch(shopByCitiesListRequestPending());

    try {
      const response = await getShopListByCities();
      dispatch(shopByCitiesListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(shopByCitiesListRequestRejected());
      return [];
    }
  };
}

export function selectShopByCitiesListResource(
  state: AppState
): ResourceType<Array<ShopsItemByCities>> {
  return state.tager.shopsByCities.shopsByCitiesList;
}

export function selectShopByCitiesList(
  state: AppState
): Array<ShopsItemByCities> {
  return selectShopByCitiesListResource(state).data;
}
