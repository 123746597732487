import { request, ResponseBody } from '@tager/web-core';

import {
  City,
  FilesType,
  FileType,
  News,
  NewsListItem,
  NewsSectionItem,
  PageByTemplateType,
  PostFull,
  PromotionListTypeItem,
  PromotionProductsItem,
  ResponseBodyWithMeta,
  RestaurantsItem,
  SalonCategoriesTypeItem,
  SalonItem,
  SearchResultItemType,
  ShoppingCenterItem,
  ShoppingCenterItemByCities,
  ShopsItemByCities,
  ShopsTypeItem,
  ShopsTypeItemByCities,
  ShopsTypeList,
  VacanciesItemByCities,
  VacancyCategoriesItemType,
  VacancyFull,
  VacancyItemType,
  VacancyStoresItemType,
} from '@/typings/model';

export type SubscribeFormPayload = {
  email: string;
  phone: string;
  cityId: number | null;
};

export type ContactsFormPayload = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  files: FilesType | null;
  vacancyId: number | null;
};

export type FeedbackFormPayload = {
  name: string;
  email: string;
  phone: string;
  storeId: number | null;
  subjectId: number | null;
  body: string;
  files: FilesType | null;
};

export type CorruptionFormPayload = {
  name: string;
  email: string;
  phone: string;
  body: string;
  storeId: number | null;
  files: Array<{ id: number | null }>;
};

export type AssistanceFormPayload = {
  name: string;
  email: string;
  phone: string;
  body: string;
  storeId: number | null;
  files: Array<{ id: number | null }>;
};

export type FilterPayload = {
  city: number;
  shop: number;
  category: number;
  subcategory: number;
  type: number;
};

export function getExampleList() {
  return request.get({ path: '/tager/example' });
}

export function createExample() {
  return request.post({ path: '/tager/example', body: { name: 'example' } });
}

export function getPagesByTemplate(
  template: string
): Promise<ResponseBody<Array<PageByTemplateType>>> {
  return request.get({ path: `/tager/pages?filter[template]=${template}` });
}

export function sendSubscribeForm(
  payload: SubscribeFormPayload
): Promise<SubscribeFormPayload> {
  return request.post({ path: '/leads/subscription', body: payload });
}

export function sendContactsForm(
  payload: ContactsFormPayload
): Promise<ContactsFormPayload> {
  return request.post({ path: '/leads/career', body: payload });
}

export function sendFeedbackForm(
  payload: FeedbackFormPayload
): Promise<FeedbackFormPayload> {
  return request.post({ path: '/leads/feedback', body: payload });
}

export function sendCorruptionForm(
  payload: CorruptionFormPayload
): Promise<CorruptionFormPayload> {
  return request.post({ path: '/leads/corruption', body: payload });
}

export function sendAssistanceForm(
  payload: CorruptionFormPayload
): Promise<CorruptionFormPayload> {
  return request.post({ path: '/leads/assistance', body: payload });
}

export function uploadFile(file: File): Promise<FileType> {
  const formData = new FormData();
  formData.append('file', file);

  return request.post({ path: '/upload/cv', body: formData });
}

export function uploadCvFile(file: File): Promise<FileType> {
  const formData = new FormData();
  formData.append('file', file);

  return request.post({ path: '/upload/cv', body: formData });
}

export function uploadCorruptionFile(file: File): Promise<FileType> {
  const formData = new FormData();
  formData.append('file', file);

  return request.post({ path: '/upload/corruption', body: formData });
}

export function uploadAssistanceFile(file: File): Promise<FileType> {
  const formData = new FormData();
  formData.append('file', file);

  return request.post({ path: '/upload/assistance', body: formData });
}

export function uploadFeedbackFile(file: File): Promise<FileType> {
  const formData = new FormData();
  formData.append('file', file);

  return request.post({ path: '/upload/feedback', body: formData });
}

export function getVacanciesList(): Promise<
  ResponseBody<Array<VacancyItemType>>
> {
  return request.get({
    path: '/vacancies/home',
  });
}

export function getVacanciesListByCities(): Promise<
  ResponseBody<Array<VacanciesItemByCities>>
> {
  return request.get({
    path: '/vacancies/cities',
  });
}

export function getVacanciesListByCategories(): Promise<
  ResponseBody<Array<VacancyCategoriesItemType>>
> {
  return request.get({
    path: '/vacancies/by-categories',
  });
}

export function getVacanciesByStore(
  cityId: number
): Promise<ResponseBody<Array<VacancyStoresItemType>>> {
  return request.get({
    path: `/vacancies/by-stores?city=${cityId}`,
  });
}

export function getVacancyCategories(
  cityId: number
): Promise<ResponseBody<Array<VacancyCategoriesItemType>>> {
  return request.get({
    path: `/vacancies/by-categories?city=${cityId}`,
  });
}

export function getVacancyByAlias(
  alias: string
): Promise<ResponseBody<VacancyFull>> {
  return request.get({
    path: `/vacancies/view/${alias}`,
  });
}

export function getNewsList(
  alias?: string
): Promise<ResponseBody<Array<NewsListItem>>> {
  let path = '/tager/blog/posts';

  if (alias) {
    path += '?' + alias;
  }

  return request.get({
    path: `${path}`,
  });
}

export function getNewsListDetailed(): Promise<ResponseBody<Array<News>>> {
  return request.get({
    path: `/news`,
  });
}

export function getNewsListByShop(
  alias?: string
): Promise<ResponseBody<Array<NewsListItem>>> {
  return request.get({
    path: `/tager/blog?${alias}`,
  });
}

export function getNewsSectionList(): Promise<
  ResponseBody<Array<NewsSectionItem>>
> {
  return request.get({
    path: `/tager/blog/categories`,
  });
}

export function getPostByAlias(alias: string): Promise<ResponseBody<PostFull>> {
  return request.get({
    path: `/tager/blog/posts/view/${alias}`,
  });
}

export function getRecipeByAlias(
  alias: string
): Promise<ResponseBody<PostFull>> {
  return request.get({
    path: `/recipes/view/${alias}`,
  });
}

export function getSearchResult(
  searchValue: string
): Promise<ResponseBody<Array<SearchResultItemType>>> {
  return request.get({
    path: `/search?query=${searchValue}`,
  });
}

export function confirmSubscription(
  token: string
): Promise<{ error?: string; success?: boolean }> {
  return request.post({
    path: `/leads/subscription/confirm/${token}`,
  });
}

export function getShopsType(): Promise<ResponseBody<ShopsTypeList>> {
  return request.get({
    path: `/shops`,
  });
}

export function getShopListByCities(): Promise<
  ResponseBody<Array<ShopsItemByCities>>
> {
  return request.get({
    path: '/shops/cities?onlyHasProducts=1',
  });
}

export function getShopsTypeByCities(): Promise<
  ResponseBody<Array<ShopsTypeItemByCities>>
> {
  return request.get({
    path: '/shops/cities/types',
  });
}

export function getProductList(
  pageSize: number,
  pageNumber: string,
  filterPayload: FilterPayload,
  searchQuery?: string
): Promise<ResponseBodyWithMeta<Array<PromotionProductsItem>>> {
  function getRequestParams(filterPayload: FilterPayload) {
    const param = new URLSearchParams();
    param.append('pageSize', pageSize.toString());
    param.append('pageNumber', pageNumber);

    if (filterPayload.city) {
      param.append('city', filterPayload.city.toString());
    }
    if (filterPayload.shop) {
      param.append('shop', filterPayload.shop.toString());
    }
    if (filterPayload.category) {
      param.append('category', filterPayload.category.toString());
    }
    if (filterPayload.subcategory) {
      param.append('subcategory', filterPayload.subcategory.toString());
    }
    if (filterPayload.type) {
      param.append('type', filterPayload.type.toString());
    }
    if (searchQuery) {
      param.append('query', searchQuery);
    }

    return param;
  }

  return request.get({
    path: `/products`,
    params: getRequestParams(filterPayload),
  });
}

export function getPromotionsSubCategories(
  category: number
): Promise<ResponseBody<Array<PromotionListTypeItem>>> {
  return request.get({
    path: `/products/subcategories?category=${category}`,
  });
}

export function getPromotionsCategories(): Promise<
  ResponseBody<Array<PromotionListTypeItem>>
> {
  return request.get({
    path: `/products/categories`,
  });
}

export function getPromotionsTypes(): Promise<
  ResponseBody<Array<PromotionListTypeItem>>
> {
  return request.get({
    path: `/products/types`,
  });
}

export function getShopByAlias(
  alias: string
): Promise<ResponseBody<ShopsTypeItem>> {
  return request.get({
    path: `/shops/view/${alias}`,
  });
}

export function getShoppingCenterByCities(): Promise<
  ResponseBody<Array<ShoppingCenterItemByCities>>
> {
  return request.get({
    path: `/shopping-centers/cities`,
  });
}

export function getShoppingCenterList(): Promise<
  ResponseBody<Array<ShoppingCenterItem>>
> {
  return request.get({
    path: `/shopping-centers`,
  });
}

export function getShoppingCenterByAlias(
  alias: string
): Promise<ResponseBody<ShoppingCenterItem>> {
  return request.get({
    path: `/shopping-centers/${alias}`,
  });
}

export function getFashionsMollsList(): Promise<
  ResponseBody<Array<ShoppingCenterItem>>
> {
  return request.get({
    path: `/fashion-malls`,
  });
}

export function getFashionsMollByAlias(
  alias: string
): Promise<ResponseBody<ShoppingCenterItem>> {
  return request.get({
    path: `/fashion-malls/${alias}`,
  });
}

export function getSalonByAlias(
  alias: string
): Promise<ResponseBody<SalonItem>> {
  return request.get({
    path: `/shopping-centers/shops/${alias}`,
  });
}

export function getSalonList(): Promise<ResponseBody<Array<SalonItem>>> {
  return request.get({
    path: `/shopping-centers/shops`,
  });
}

export function getSalonCategories(): Promise<
  ResponseBody<Array<SalonCategoriesTypeItem>>
> {
  return request.get({
    path: `/shopping-centers/categories`,
  });
}

export function getRestaurantsList(
  alias?: string
): Promise<ResponseBody<Array<RestaurantsItem>>> {
  let path = '/restaurants';

  if (alias) {
    path += '?' + alias;
  }

  return request.get({
    path: `${path}`,
  });
}

export function getRestaurantsView(
  id?: number
): Promise<ResponseBody<RestaurantsItem>> {
  return request.get({
    path: `/restaurants/${id}`,
  });
}

export function getCitiesList(): Promise<ResponseBody<Array<City>>> {
  return request.get({
    path: '/cities',
  });
}
