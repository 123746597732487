import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { ShoppingCenterItem } from '@/typings/model';
import { getShoppingCenterList } from '@/services/requests';

const shoppingCenterListLoader = createResourceLoader<
  Array<ShoppingCenterItem>
>([]);

type State = {
  shoppingCenterList: ResourceType<Array<ShoppingCenterItem>>;
};

const initialState: State = {
  shoppingCenterList: shoppingCenterListLoader.getInitialResource(),
};

const shoppingCenterListSlice = createSlice({
  name: 'shoppingCenterList',
  initialState,
  reducers: {
    shoppingCenterRequestPending(state) {
      state.shoppingCenterList = shoppingCenterListLoader.pending();
    },
    shoppingCenterRequestFulfilled(
      state,
      action: PayloadAction<Array<ShoppingCenterItem>>
    ) {
      state.shoppingCenterList = shoppingCenterListLoader.fulfill(
        action.payload
      );
    },
    shoppingCenterRequestRejected(state) {
      state.shoppingCenterList = shoppingCenterListLoader.reject();
    },
  },
});

const { actions, reducer } = shoppingCenterListSlice;
export const {
  shoppingCenterRequestPending,
  shoppingCenterRequestFulfilled,
  shoppingCenterRequestRejected,
} = actions;

export default reducer;

export function getShoppingCenterListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<ShoppingCenterItem>>> {
  return async (dispatch, getState) => {
    const shopsTypeResource = selectShoppingCenterListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      shopsTypeResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return shopsTypeResource.data;
    }

    dispatch(shoppingCenterRequestPending());

    try {
      const response = await getShoppingCenterList();
      dispatch(shoppingCenterRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(shoppingCenterRequestRejected());
      return [];
    }
  };
}

export function selectShoppingCenterListResource(
  state: AppState
): ResourceType<Array<ShoppingCenterItem>> {
  return state.tager.shoppingCenterList.shoppingCenterList;
}

export function selectShoppingCenterList(
  state: AppState
): Array<ShoppingCenterItem> {
  return selectShoppingCenterListResource(state).data;
}
