import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { ShoppingCenterItemByCities } from '@/typings/model';
import { getShoppingCenterByCities } from '@/services/requests';

const shoppingCenterByCitiesLoader = createResourceLoader<
  Array<ShoppingCenterItemByCities>
>([]);

type State = {
  shoppingCenterList: ResourceType<Array<ShoppingCenterItemByCities>>;
};

const initialState: State = {
  shoppingCenterList: shoppingCenterByCitiesLoader.getInitialResource(),
};

const shoppingCenterByCitiesSlice = createSlice({
  name: 'shoppingCenterByCities',
  initialState,
  reducers: {
    shoppingCenterRequestPending(state) {
      state.shoppingCenterList = shoppingCenterByCitiesLoader.pending();
    },
    shoppingCenterRequestFulfilled(
      state,
      action: PayloadAction<Array<ShoppingCenterItemByCities>>
    ) {
      state.shoppingCenterList = shoppingCenterByCitiesLoader.fulfill(
        action.payload
      );
    },
    shoppingCenterRequestRejected(state) {
      state.shoppingCenterList = shoppingCenterByCitiesLoader.reject();
    },
  },
});

const { actions, reducer } = shoppingCenterByCitiesSlice;
export const {
  shoppingCenterRequestPending,
  shoppingCenterRequestFulfilled,
  shoppingCenterRequestRejected,
} = actions;

export default reducer;

export function getShoppingCenterListByCitiesThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<ShoppingCenterItemByCities>>> {
  return async (dispatch, getState) => {
    const shopsTypeResource = selectShoppingCenterByCitiesResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      shopsTypeResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return shopsTypeResource.data;
    }

    dispatch(shoppingCenterRequestPending());

    try {
      const response = await getShoppingCenterByCities();
      dispatch(shoppingCenterRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(shoppingCenterRequestRejected());
      return [];
    }
  };
}

export function selectShoppingCenterByCitiesResource(
  state: AppState
): ResourceType<Array<ShoppingCenterItemByCities>> {
  return state.tager.shoppingCenterByCities.shoppingCenterList;
}

export function selectShoppingCenterByCities(
  state: AppState
): Array<ShoppingCenterItemByCities> {
  return selectShoppingCenterByCitiesResource(state).data;
}
