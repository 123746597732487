import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { getShopsType } from '@/services/requests';
import { ShopsTypeList } from '@/typings/model';

const shopsTypeLoader = createResourceLoader<ShopsTypeList>([]);

type State = {
  shopsTypeList: ResourceType<ShopsTypeList>;
};

const initialState: State = {
  shopsTypeList: shopsTypeLoader.getInitialResource(),
};

const shopsTypeSlice = createSlice({
  name: 'shopsType',
  initialState,
  reducers: {
    shopsTypeRequestPending(state) {
      state.shopsTypeList = shopsTypeLoader.pending();
    },
    shopsTypeRequestFulfilled(state, action: PayloadAction<ShopsTypeList>) {
      state.shopsTypeList = shopsTypeLoader.fulfill(action.payload);
    },
    shopsTypeRequestRejected(state) {
      state.shopsTypeList = shopsTypeLoader.reject();
    },
  },
});

const { actions, reducer } = shopsTypeSlice;
export const {
  shopsTypeRequestPending,
  shopsTypeRequestFulfilled,
  shopsTypeRequestRejected,
} = actions;

export default reducer;

export function getShopsTypeListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<ShopsTypeList>> {
  return async (dispatch, getState) => {
    const shopsTypeResource = selectShopsTypeResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      shopsTypeResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return shopsTypeResource.data;
    }

    dispatch(shopsTypeRequestPending());

    try {
      const response = await getShopsType();
      dispatch(shopsTypeRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(shopsTypeRequestRejected());
      return [];
    }
  };
}

export function selectShopsTypeResource(
  state: AppState
): ResourceType<ShopsTypeList> {
  return state.tager.shopsType.shopsTypeList;
}

export function selectShopsType(state: AppState): ShopsTypeList {
  return selectShopsTypeResource(state).data;
}
