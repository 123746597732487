import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createResourceLoader, ResourceType } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
// import {
//   getSearchPostList,
//   getSearchProductList,
//   getSearchCategoryList,
// } from '@/services/requests';
// import {
//   PostSearchShort,
//   ProductsCategoryType,
//   ProductType,
// } from '@/typings/model';

// const searchProductLoader = createResourceLoader<Array<ProductType>>([]);
// const searchPostLoader = createResourceLoader<Array<PostSearchShort>>([]);
// const searchCategoryLoader = createResourceLoader<Array<ProductsCategoryType>>(
//   []
// );

type SearchKeyType = 'searchPage' | 'searchInput';

type StateType = {
  data: {
    [key in SearchKeyType]: {
      // searchProductList: ResourceType<Array<ProductType>>;
      // searchPostList: ResourceType<Array<PostSearchShort>>;
      // searchCategoryList: ResourceType<Array<ProductsCategoryType>>;
    };
  };
  query: string;
};

const initialState: StateType = {
  data: {
    searchPage: {
      // searchProductList: searchProductLoader.getInitialResource(),
      // searchPostList: searchPostLoader.getInitialResource(),
      // searchCategoryList: searchCategoryLoader.getInitialResource(),
    },
    searchInput: {
      // searchProductList: searchProductLoader.getInitialResource(),
      // searchPostList: searchPostLoader.getInitialResource(),
      // searchCategoryList: searchCategoryLoader.getInitialResource(),
    },
  },
  query: '',
};

type SearchDataTypePayload = { displayType: SearchKeyType };

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // /** Product list */
    // searchProductRequestPending(
    //   state,
    //   action: PayloadAction<SearchDataTypePayload>
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchProductList = searchProductLoader.pending();
    // },
    // searchProductRequestFulfilled(
    //   state,
    //   action: PayloadAction<
    //     SearchDataTypePayload & {
    //       data: Array<ProductType>;
    //     }
    //   >
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchProductList = searchProductLoader.fulfill(action.payload.data);
    // },
    // searchProductRequestRejected(
    //   state,
    //   action: PayloadAction<SearchDataTypePayload>
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchProductList = searchProductLoader.reject();
    // },
    // searchProductRequestCancel(
    //   state,
    //   action: PayloadAction<SearchDataTypePayload>
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchProductList = searchProductLoader.cancel();
    // },

    // /** Post list */
    // searchPostRequestPending(
    //   state,
    //   action: PayloadAction<SearchDataTypePayload>
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchPostList = searchPostLoader.pending();
    // },
    // searchPostRequestFulfilled(
    //   state,
    //   action: PayloadAction<
    //     SearchDataTypePayload & { data: Array<PostSearchShort> }
    //   >
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchPostList = searchPostLoader.fulfill(action.payload.data);
    // },
    // searchPostRequestRejected(
    //   state,
    //   action: PayloadAction<SearchDataTypePayload>
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchPostList = searchPostLoader.reject();
    // },
    // searchPostRequestCancel(
    //   state,
    //   action: PayloadAction<SearchDataTypePayload>
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchPostList = searchPostLoader.cancel();
    // },

    // /** Category list */
    // searchCategoryRequestPending(
    //   state,
    //   action: PayloadAction<SearchDataTypePayload>
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchCategoryList = searchCategoryLoader.pending();
    // },
    // searchCategoryRequestFulfilled(
    //   state,
    //   action: PayloadAction<
    //     SearchDataTypePayload & { data: Array<ProductsCategoryType> }
    //   >
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchCategoryList = searchCategoryLoader.fulfill(action.payload.data);
    // },
    // searchCategoryRequestRejected(
    //   state,
    //   action: PayloadAction<SearchDataTypePayload>
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchCategoryList = searchCategoryLoader.reject();
    // },
    // searchCategoryRequestCancel(
    //   state,
    //   action: PayloadAction<SearchDataTypePayload>
    // ) {
    //   state.data[
    //     action.payload.displayType
    //   ].searchCategoryList = searchCategoryLoader.cancel();
    // },
    /** Query */
    queryAdded(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
  },
});

const { actions, reducer } = searchSlice;

export const {
  // searchProductRequestPending,
  // searchProductRequestFulfilled,
  // searchProductRequestRejected,
  // searchProductRequestCancel,
  // searchPostRequestPending,
  // searchPostRequestFulfilled,
  // searchPostRequestRejected,
  // searchPostRequestCancel,
  // searchCategoryRequestPending,
  // searchCategoryRequestFulfilled,
  // searchCategoryRequestRejected,
  // searchCategoryRequestCancel,
  queryAdded,
} = actions;

// export function getSearchProductListThunk(
//   query: string,
//   displayType: SearchKeyType
// ): AppThunk<Promise<Array<ProductType>>> {
//   return async (dispatch) => {
//     if (!query) {
//       dispatch(searchProductRequestCancel({ displayType: displayType }));
//       return [];
//     }
//
//     try {
//       dispatch(searchProductRequestPending({ displayType: displayType }));
//       const response = await getSearchProductList({
//         query,
//         ...(displayType === 'searchInput' ? { count: 6 } : {}),
//       });
//       dispatch(
//         searchProductRequestFulfilled({
//           data: response.data,
//           displayType: displayType,
//         })
//       );
//
//       return response.data;
//     } catch (error) {
//       dispatch(searchProductRequestRejected({ displayType: displayType }));
//       return [];
//     }
//   };
// }
//
// export function getSearchPostListThunk(
//   query: string,
//   displayType: SearchKeyType
// ): AppThunk<Promise<Array<PostSearchShort>>> {
//   return async (dispatch) => {
//     if (!query) {
//       dispatch(searchPostRequestCancel({ displayType: displayType }));
//       return [];
//     }
//
//     try {
//       dispatch(searchPostRequestPending({ displayType: displayType }));
//       const response = await getSearchPostList({
//         query,
//         ...(displayType === 'searchInput' ? { count: 5 } : {}),
//       });
//       dispatch(
//         searchPostRequestFulfilled({
//           data: response.data,
//           displayType: displayType,
//         })
//       );
//
//       return response.data;
//     } catch (error) {
//       dispatch(searchPostRequestRejected({ displayType: displayType }));
//       return [];
//     }
//   };
// }
//
// export function getSearchCategoryListThunk(
//   query: string,
//   displayType: SearchKeyType
// ): AppThunk<Promise<Array<ProductsCategoryType>>> {
//   return async (dispatch) => {
//     if (!query) {
//       dispatch(searchCategoryRequestCancel({ displayType: displayType }));
//       return [];
//     }
//
//     try {
//       dispatch(searchCategoryRequestPending({ displayType: displayType }));
//       const response = await getSearchCategoryList({
//         query,
//         ...(displayType === 'searchInput' ? { count: 5 } : {}),
//       });
//       dispatch(
//         searchCategoryRequestFulfilled({
//           displayType: displayType,
//           data: response.data,
//         })
//       );
//       return response.data;
//     } catch (error) {
//       dispatch(searchCategoryRequestRejected({ displayType: displayType }));
//       return [];
//     }
//   };
// }

export function selectQuery(state: AppState): string {
  return state.tager.search.query;
}

// export function selectFindProductListResource(
//   state: AppState,
//   displayType: SearchKeyType
// ): ResourceType<Array<ProductType>> {
//   return state.pages.search.data[displayType].searchProductList;
// }
//
// export function selectFindPostListResource(
//   state: AppState,
//   displayType: SearchKeyType
// ): ResourceType<Array<PostSearchShort>> {
//   return state.pages.search.data[displayType].searchPostList;
// }
//
// export function selectFindCategoryListResource(
//   state: AppState,
//   displayType: SearchKeyType
// ): ResourceType<Array<ProductsCategoryType>> {
//   return state.pages.search.data[displayType].searchCategoryList;
// }

// export function selectFindProductList(
//   state: AppState,
//   displayType: SearchKeyType
// ): Array<ProductType> {
//   return selectFindProductListResource(state, displayType).data;
// }
//
// export function selectFindPostList(
//   state: AppState,
//   displayType: SearchKeyType
// ): Array<PostSearchShort> {
//   return selectFindPostListResource(state, displayType).data;
// }
//
// export function selectFindCategoryList(
//   state: AppState,
//   displayType: SearchKeyType
// ): Array<ProductsCategoryType> {
//   return selectFindCategoryListResource(state, displayType).data;
// }

export default reducer;
