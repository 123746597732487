import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { PromotionListTypeItem } from '@/typings/model';
import { getPromotionsSubCategories } from '@/services/requests';

const promotionsSubCategoriesLoader = createResourceLoader<
  Array<PromotionListTypeItem>
>([]);

type State = {
  promotionsSubCategoriesList: ResourceType<Array<PromotionListTypeItem>>;
};

const initialState: State = {
  promotionsSubCategoriesList:
    promotionsSubCategoriesLoader.getInitialResource(),
};

const promotionsSubCategoriesSlice = createSlice({
  name: 'promotionsSubCategories',
  initialState,
  reducers: {
    promotionsSubCategoriesListRequestPending(state) {
      state.promotionsSubCategoriesList =
        promotionsSubCategoriesLoader.pending();
    },
    promotionsSubCategoriesListRequestFulfilled(
      state,
      action: PayloadAction<Array<PromotionListTypeItem>>
    ) {
      state.promotionsSubCategoriesList = promotionsSubCategoriesLoader.fulfill(
        action.payload
      );
    },
    promotionsSubCategoriesListRequestRejected(state) {
      state.promotionsSubCategoriesList =
        promotionsSubCategoriesLoader.reject();
    },
  },
});

const { actions, reducer } = promotionsSubCategoriesSlice;
export const {
  promotionsSubCategoriesListRequestPending,
  promotionsSubCategoriesListRequestFulfilled,
  promotionsSubCategoriesListRequestRejected,
} = actions;

export default reducer;

export function getPromotionsSubCategoriesListThunk(options: {
  activeCategory: number;
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<PromotionListTypeItem>>> {
  return async (dispatch, getState) => {
    dispatch(promotionsSubCategoriesListRequestPending());

    try {
      const response = await getPromotionsSubCategories(options.activeCategory);
      dispatch(promotionsSubCategoriesListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(promotionsSubCategoriesListRequestRejected());
      return [];
    }
  };
}

export function selectPromotionsSubCategoriesListResource(
  state: AppState
): ResourceType<Array<PromotionListTypeItem>> {
  return state.tager.promotionsSubcategories.promotionsSubCategoriesList;
}

export function selectPromotionsSubCategoriesList(
  state: AppState
): Array<PromotionListTypeItem> {
  return selectPromotionsSubCategoriesListResource(state).data;
}
