import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { VacanciesItemByCities } from '@/typings/model';
import { getVacanciesListByCities } from '@/services/requests';

const vacancyListByCitiesLoader = createResourceLoader<
  Array<VacanciesItemByCities>
>([]);

type State = {
  vacancyByCitiesList: ResourceType<Array<VacanciesItemByCities>>;
};

const initialState: State = {
  vacancyByCitiesList: vacancyListByCitiesLoader.getInitialResource(),
};

const vacanciesByCitiesSlice = createSlice({
  name: 'vacanciesByCities',
  initialState,
  reducers: {
    vacancyByCitiesListRequestPending(state) {
      state.vacancyByCitiesList = vacancyListByCitiesLoader.pending();
    },
    vacancyByCitiesListRequestFulfilled(
      state,
      action: PayloadAction<Array<VacanciesItemByCities>>
    ) {
      state.vacancyByCitiesList = vacancyListByCitiesLoader.fulfill(
        action.payload
      );
    },
    vacancyByCitiesListRequestRejected(state) {
      state.vacancyByCitiesList = vacancyListByCitiesLoader.reject();
    },
  },
});

const { actions, reducer } = vacanciesByCitiesSlice;
export const {
  vacancyByCitiesListRequestPending,
  vacancyByCitiesListRequestFulfilled,
  vacancyByCitiesListRequestRejected,
} = actions;

export default reducer;

export function getVacancyByCitiesListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<VacanciesItemByCities>>> {
  return async (dispatch, getState) => {
    const vacancyByCitiesListResource = selectVacancyByCitiesListResource(
      getState()
    );

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      vacancyByCitiesListResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return vacancyByCitiesListResource.data;
    }

    dispatch(vacancyByCitiesListRequestPending());

    try {
      const response = await getVacanciesListByCities();
      dispatch(vacancyByCitiesListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(vacancyByCitiesListRequestRejected());
      return [];
    }
  };
}

export function selectVacancyByCitiesListResource(
  state: AppState
): ResourceType<Array<VacanciesItemByCities>> {
  return state.tager.vacanciesByCities.vacancyByCitiesList;
}

export function selectVacancyByCitiesList(
  state: AppState
): Array<VacanciesItemByCities> {
  return selectVacancyByCitiesListResource(state).data;
}
