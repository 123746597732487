import React from 'react';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/node';
import Head from 'next/head';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import App from 'next/app';

import { AdminBar } from '@tager/web-panel';
import { useAnalytics } from '@tager/web-analytics';
import { isServer, useFixedVhProperty, useProgressBar } from '@tager/web-core';
import { ModalProvider } from '@tager/web-components';
import { cookie } from '@tager/web-core';

import withRedux from '@/hocs/withRedux';
import withPerfLogs from '@/hocs/withPerfLogs';
import '@/assets/css/index.css';
import { getMenuItemListThunk } from '@/store/reducers/tager/menus';
import { getSettingItemListThunk } from '@/store/reducers/tager/settings';
import { CustomApp_Component } from '@/typings/hocs';
import { getVacancyByCitiesListThunk } from '@/store/reducers/vacanciesCities';

Sentry.init({
  enabled:
    process.env.NODE_ENV === 'production' &&
    process.env.NEXT_PUBLIC_ENV !== 'local',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: [
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    process.env.NEXT_PUBLIC_ENV,
  ].join('_'),
});

gsap.registerPlugin(ScrollTrigger);

function clearCookies(except: string[]) {
  Object.keys(Cookies.get()).forEach((cookie) => {
    if (!except.includes(cookie)) {
      Cookies.remove(cookie);
      Cookies.remove(cookie, { domain: '.korona.by' });
    }
  });
}

/**
 * Custom App documentation
 * https://nextjs.org/docs/advanced-features/custom-app
 */
const CustomApp: CustomApp_Component = (props) => {
  useProgressBar({ showSpinner: false });

  useFixedVhProperty();

  const analyticsCookiesChecked = cookie.get('infoPopupClosed') === '2';
  if (!isServer()) {
    clearCookies(['infoPopupClosed']);
  }
  if (analyticsCookiesChecked) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAnalytics();
  }

  const { Component, pageProps } = props;

  // Workaround for https://github.com/zeit/next.js/issues/8592
  // @ts-ignore
  const { err } = props;
  const modifiedPageProps = { ...pageProps, err };
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
          // content="width=1260,user-scalable=yes"
        />
      </Head>
      <AdminBar />
      <ModalProvider>
        <Component {...modifiedPageProps} />
      </ModalProvider>
    </>
  );
};

/**
 * Only use this method if you have blocking data requirements for
 * every single page in your application. This disables the ability to
 * perform automatic static optimization, causing every page in your app to
 * be server-side rendered.
 *
 * Reference: https://nextjs.org/docs/advanced-features/custom-app
 */
// CustomApp.getInitialProps = async (appContext) => {
//   /** calls page's `getInitialProps` and fills `appProps.pageProps` */
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps };
// };

CustomApp.getInitialProps = async (appContext) => {
  const { ctx } = appContext;
  if (isServer() && ctx.req) {
    await Promise.all([
      ctx.store.dispatch(getMenuItemListThunk('top')),
      ctx.store.dispatch(getMenuItemListThunk('main')),
      ctx.store.dispatch(getMenuItemListThunk('footer')),
      ctx.store.dispatch(getSettingItemListThunk()),
      ctx.store.dispatch(getVacancyByCitiesListThunk()),
    ]);
  }
  /** calls page's `getInitialProps` and fills `appProps.pageProps` */
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default withRedux(withPerfLogs(CustomApp));
