import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { ShoppingCenterItem } from '@/typings/model';
import { getFashionsMollsList } from '@/services/requests';

const fashionMollsListLoader = createResourceLoader<Array<ShoppingCenterItem>>(
  []
);

type State = {
  fashionMollsList: ResourceType<Array<ShoppingCenterItem>>;
};

const initialState: State = {
  fashionMollsList: fashionMollsListLoader.getInitialResource(),
};

const fashionMollsListSlice = createSlice({
  name: 'fashionMollsList',
  initialState,
  reducers: {
    fashionMollsRequestPending(state) {
      state.fashionMollsList = fashionMollsListLoader.pending();
    },
    fashionMollsRequestFulfilled(
      state,
      action: PayloadAction<Array<ShoppingCenterItem>>
    ) {
      state.fashionMollsList = fashionMollsListLoader.fulfill(action.payload);
    },
    fashionMollsRequestRejected(state) {
      state.fashionMollsList = fashionMollsListLoader.reject();
    },
  },
});

const { actions, reducer } = fashionMollsListSlice;
export const {
  fashionMollsRequestPending,
  fashionMollsRequestFulfilled,
  fashionMollsRequestRejected,
} = actions;

export default reducer;

export function getFashionMollsListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<ShoppingCenterItem>>> {
  return async (dispatch, getState) => {
    const shopsTypeResource = selectFashionMollsListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      shopsTypeResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return shopsTypeResource.data;
    }

    dispatch(fashionMollsRequestPending());

    try {
      const response = await getFashionsMollsList();
      dispatch(fashionMollsRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(fashionMollsRequestRejected());
      return [];
    }
  };
}

export function selectFashionMollsListResource(
  state: AppState
): ResourceType<Array<ShoppingCenterItem>> {
  return state.tager.fashionMollsList.fashionMollsList;
}

export function selectFashionMollsList(
  state: AppState
): Array<ShoppingCenterItem> {
  return selectFashionMollsListResource(state).data;
}
