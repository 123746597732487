import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { SalonItem } from '@/typings/model';
import { getSalonList } from '@/services/requests';

const salonListLoader = createResourceLoader<Array<SalonItem>>([]);

type State = {
  salonList: ResourceType<Array<SalonItem>>;
};

const initialState: State = {
  salonList: salonListLoader.getInitialResource(),
};

const salonListSlice = createSlice({
  name: 'salons',
  initialState,
  reducers: {
    salonListRequestPending(state) {
      state.salonList = salonListLoader.pending();
    },
    salonListRequestFulfilled(state, action: PayloadAction<Array<SalonItem>>) {
      state.salonList = salonListLoader.fulfill(action.payload);
    },
    salonListRequestRejected(state) {
      state.salonList = salonListLoader.reject();
    },
  },
});

const { actions, reducer } = salonListSlice;
export const {
  salonListRequestPending,
  salonListRequestFulfilled,
  salonListRequestRejected,
} = actions;

export default reducer;

export function getSalonListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<SalonItem>>> {
  return async (dispatch, getState) => {
    const salonListResource = selectSalonListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      salonListResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return salonListResource.data;
    }

    dispatch(salonListRequestPending());

    try {
      const response = await getSalonList();
      dispatch(salonListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(salonListRequestRejected());
      return [];
    }
  };
}

export function selectSalonListResource(
  state: AppState
): ResourceType<Array<SalonItem>> {
  return state.tager.salons.salonList;
}

export function selectSalonList(state: AppState): Array<SalonItem> {
  return selectSalonListResource(state).data;
}
