import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapEntry, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { PostFull } from '@/typings/model';
import { getPostByAlias } from '@/services/requests';

type State = {
  postFullMap: Record<string, PostFull>;
};

const initialState: State = {
  postFullMap: {},
};

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    /** Post full */
    postAdded(state, action: PayloadAction<MapEntry<string, PostFull>>) {
      state.postFullMap[action.payload.key] = action.payload.value;
    },
  },
});

const { actions, reducer } = postSlice;
export const { postAdded } = actions;

export default reducer;

export function getPostByAliasThunk(
  postPath: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<PostFull>>> {
  return async (dispatch, getState) => {
    try {
      const post = selectPostByAlias(getState(), postPath);

      if (!options?.shouldInvalidate && post) {
        return post;
      }

      const response = await getPostByAlias(postPath);

      dispatch(
        postAdded({
          key: postPath,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      return null;
    }
  };
}

export function selectPostByAlias(
  state: AppState,
  postAlias: string
): PostFull | undefined {
  return state.tager.post.postFullMap[postAlias];
}
