import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapEntry, Nullable } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { VacancyFull } from '@/typings/model';
import { getVacancyByAlias } from '@/services/requests';

type State = {
  vacancyFullMap: Record<string, VacancyFull>;
};

const initialState: State = {
  vacancyFullMap: {},
};

const vacancySlice = createSlice({
  name: 'vacancy',
  initialState,
  reducers: {
    /** Vacancy full */
    vacancyAdded(state, action: PayloadAction<MapEntry<string, VacancyFull>>) {
      state.vacancyFullMap[action.payload.key] = action.payload.value;
    },
  },
});

const { actions, reducer } = vacancySlice;
export const { vacancyAdded } = actions;

export default reducer;

export function getVacancyByAliasThunk(
  vacancyPath: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<VacancyFull>>> {
  return async (dispatch, getState) => {
    try {
      const vacancy = selectVacancyByAlias(getState(), vacancyPath);

      if (!options?.shouldInvalidate && vacancy) {
        return vacancy;
      }

      const response = await getVacancyByAlias(vacancyPath);

      dispatch(
        vacancyAdded({
          key: vacancyPath,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      return null;
    }
  };
}

export function selectVacancyByAlias(
  state: AppState,
  vacancyAlias: string
): VacancyFull | undefined {
  return state.tager.vacancy.vacancyFullMap[vacancyAlias];
}
