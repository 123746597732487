import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { VacancyCategoriesItemType } from '@/typings/model';
import { getVacanciesListByCategories } from '@/services/requests';

const vacancyListByCategoriesLoader = createResourceLoader<
  Array<VacancyCategoriesItemType>
>([]);

type State = {
  vacancyByCategoriesList: ResourceType<Array<VacancyCategoriesItemType>>;
};

const initialState: State = {
  vacancyByCategoriesList: vacancyListByCategoriesLoader.getInitialResource(),
};

const vacanciesByCitiesSlice = createSlice({
  name: 'vacanciesByCategories',
  initialState,
  reducers: {
    vacancyByCategoriesListRequestPending(state) {
      state.vacancyByCategoriesList = vacancyListByCategoriesLoader.pending();
    },
    vacancyByCategoriesListRequestFulfilled(
      state,
      action: PayloadAction<Array<VacancyCategoriesItemType>>
    ) {
      state.vacancyByCategoriesList = vacancyListByCategoriesLoader.fulfill(
        action.payload
      );
    },
    vacancyByCategoriesListRequestRejected(state) {
      state.vacancyByCategoriesList = vacancyListByCategoriesLoader.reject();
    },
  },
});

const { actions, reducer } = vacanciesByCitiesSlice;
export const {
  vacancyByCategoriesListRequestPending,
  vacancyByCategoriesListRequestFulfilled,
  vacancyByCategoriesListRequestRejected,
} = actions;

export default reducer;

export function getVacancyByCategoriesListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<VacancyCategoriesItemType>>> {
  return async (dispatch, getState) => {
    const vacancyByCategoriesListResource =
      selectVacancyByCategoriesListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      vacancyByCategoriesListResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return vacancyByCategoriesListResource.data;
    }

    dispatch(vacancyByCategoriesListRequestPending());

    try {
      const response = await getVacanciesListByCategories();
      dispatch(vacancyByCategoriesListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(vacancyByCategoriesListRequestRejected());
      return [];
    }
  };
}

export function selectVacancyByCategoriesListResource(
  state: AppState
): ResourceType<Array<VacancyCategoriesItemType>> {
  return state.tager.vacanciesByCategories.vacancyByCategoriesList;
}

export function selectVacancyByCategoriesList(
  state: AppState
): Array<VacancyCategoriesItemType> {
  return selectVacancyByCategoriesListResource(state).data;
}
