import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { SalonCategoriesTypeItem } from '@/typings/model';
import { getSalonCategories } from '@/services/requests';

const salonCategoriesLoader = createResourceLoader<
  Array<SalonCategoriesTypeItem>
>([]);

type State = {
  salonCategoriesList: ResourceType<Array<SalonCategoriesTypeItem>>;
};

const initialState: State = {
  salonCategoriesList: salonCategoriesLoader.getInitialResource(),
};

const salonCategoriesSlice = createSlice({
  name: 'salonCategories',
  initialState,
  reducers: {
    salonCategoriesRequestPending(state) {
      state.salonCategoriesList = salonCategoriesLoader.pending();
    },
    salonCategoriesRequestFulfilled(
      state,
      action: PayloadAction<Array<SalonCategoriesTypeItem>>
    ) {
      state.salonCategoriesList = salonCategoriesLoader.fulfill(action.payload);
    },
    salonCategoriesRequestRejected(state) {
      state.salonCategoriesList = salonCategoriesLoader.reject();
    },
  },
});

const { actions, reducer } = salonCategoriesSlice;
export const {
  salonCategoriesRequestPending,
  salonCategoriesRequestFulfilled,
  salonCategoriesRequestRejected,
} = actions;

export default reducer;

export function getSalonCategoriesThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<SalonCategoriesTypeItem>>> {
  return async (dispatch, getState) => {
    const salonCategoriesResource = selectSalonCategoriesResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      salonCategoriesResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return salonCategoriesResource.data;
    }

    dispatch(salonCategoriesRequestPending());

    try {
      const response = await getSalonCategories();
      dispatch(salonCategoriesRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(salonCategoriesRequestRejected());
      return [];
    }
  };
}

export function selectSalonCategoriesResource(
  state: AppState
): ResourceType<Array<SalonCategoriesTypeItem>> {
  return state.tager.salonCategories.salonCategoriesList;
}

export function selectSalonCategories(
  state: AppState
): Array<SalonCategoriesTypeItem> {
  return selectSalonCategoriesResource(state).data;
}
