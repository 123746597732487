import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { VacancyItemType } from '@/typings/model';
import { getVacanciesList } from '@/services/requests';

const vacancyListLoader = createResourceLoader<Array<VacancyItemType>>([]);

type State = {
  vacancyShortList: ResourceType<Array<VacancyItemType>>;
};

const initialState: State = {
  vacancyShortList: vacancyListLoader.getInitialResource(),
};

const blogSlice = createSlice({
  name: 'vacancies',
  initialState,
  reducers: {
    vacancyListRequestPending(state) {
      state.vacancyShortList = vacancyListLoader.pending();
    },
    vacancyListRequestFulfilled(
      state,
      action: PayloadAction<Array<VacancyItemType>>
    ) {
      state.vacancyShortList = vacancyListLoader.fulfill(action.payload);
    },
    vacancyListRequestRejected(state) {
      state.vacancyShortList = vacancyListLoader.reject();
    },
  },
});

const { actions, reducer } = blogSlice;
export const {
  vacancyListRequestPending,
  vacancyListRequestFulfilled,
  vacancyListRequestRejected,
} = actions;

export default reducer;

export function getVacancyListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<VacancyItemType>>> {
  return async (dispatch, getState) => {
    const vacancyListResource = selectVacancyListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      vacancyListResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return vacancyListResource.data;
    }

    dispatch(vacancyListRequestPending());

    try {
      const response = await getVacanciesList();
      dispatch(vacancyListRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(vacancyListRequestRejected());
      return [];
    }
  };
}

export function selectVacancyListResource(
  state: AppState
): ResourceType<Array<VacancyItemType>> {
  return state.tager.vacancies.vacancyShortList;
}

export function selectVacancyList(state: AppState): Array<VacancyItemType> {
  return selectVacancyListResource(state).data;
}
