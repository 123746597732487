import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { RestaurantsItem } from '@/typings/model';
import { getRestaurantsList } from '@/services/requests';

const restaurantsListLoader = createResourceLoader<Array<RestaurantsItem>>([]);

type State = {
  restaurantsList: ResourceType<Array<RestaurantsItem>>;
};

const initialState: State = {
  restaurantsList: restaurantsListLoader.getInitialResource(),
};

const restaurantsListSlice = createSlice({
  name: 'restaurantsList',
  initialState,
  reducers: {
    restaurantsRequestPending(state) {
      state.restaurantsList = restaurantsListLoader.pending();
    },
    restaurantsRequestFulfilled(
      state,
      action: PayloadAction<Array<RestaurantsItem>>
    ) {
      state.restaurantsList = restaurantsListLoader.fulfill(action.payload);
    },
    restaurantsRequestRejected(state) {
      state.restaurantsList = restaurantsListLoader.reject();
    },
  },
});

const { actions, reducer } = restaurantsListSlice;
export const {
  restaurantsRequestPending,
  restaurantsRequestFulfilled,
  restaurantsRequestRejected,
} = actions;

export default reducer;

export function getRestaurantsListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<RestaurantsItem>>> {
  return async (dispatch, getState) => {
    const shopsTypeResource = selectRestaurantsListResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      shopsTypeResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return shopsTypeResource.data;
    }

    dispatch(restaurantsRequestPending());

    try {
      const response = await getRestaurantsList();
      dispatch(restaurantsRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(restaurantsRequestRejected());
      return [];
    }
  };
}

export function selectRestaurantsListResource(
  state: AppState
): ResourceType<Array<RestaurantsItem>> {
  return state.tager.restaurantsList.restaurantsList;
}

export function selectRestaurantsList(state: AppState): Array<RestaurantsItem> {
  return selectRestaurantsListResource(state).data;
}
