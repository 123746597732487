import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createResourceLoader, Nullable, ResourceType } from '@tager/web-core';

import { MetaPage, PromotionProductsItem } from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';
import { getProductList } from '@/services/requests';
import { InitialValueProps } from '@/modules/Promotions/components/PromotionContent';

const productListLoader = createResourceLoader<Array<PromotionProductsItem>>(
  []
);

type State = {
  productListMap: ResourceType<Array<PromotionProductsItem>>;
  metaPage: MetaPage | null;
};

const initialState: State = {
  productListMap: productListLoader.getInitialResource(),
  metaPage: null,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    productListRequestPending(state) {
      state.productListMap = productListLoader.pending();
    },
    productListRequestFulfilled(
      state,
      action: PayloadAction<Array<PromotionProductsItem>>
    ) {
      state.productListMap = productListLoader.fulfill(action.payload);
    },
    productListRequestRejected(state) {
      state.productListMap = productListLoader.reject();
    },

    addedMetaPage(state, action: PayloadAction<MetaPage>) {
      state.metaPage = action.payload;
    },
  },
});

const { actions, reducer } = productSlice;

export const {
  productListRequestPending,
  productListRequestFulfilled,
  productListRequestRejected,
  addedMetaPage,
} = actions;

export default reducer;

export function getProductListThunk(
  itemsPerPage: number,
  page: string | undefined,
  searchQuery: string,
  values?: InitialValueProps | undefined
): AppThunk<Promise<Array<PromotionProductsItem>>> {
  return async (dispatch, getState) => {
    try {
      dispatch(productListRequestPending());

      const response = await getProductList(
        itemsPerPage,
        page ? page : '1',
        {
          city: values?.city ?? 0,
          shop: values?.shop ?? 0,
          category: values?.category ?? 0,
          subcategory: values?.subcategory ?? 0,
          type: values?.type ?? 0,
        },
        searchQuery
      );

      dispatch(addedMetaPage(response.meta));
      dispatch(productListRequestFulfilled(response.data));

      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(productListRequestRejected());
      return [];
    }
  };
}

export function selectProductList(
  state: AppState
): ResourceType<Array<PromotionProductsItem>> {
  return state.product.productListMap;
}

export function selectMetaPage(state: AppState): Nullable<MetaPage> {
  return state.product.metaPage;
}
